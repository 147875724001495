@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
@import url("https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css");
$main-bg: #0f051d;
$bg-light: #33254b;
$purple: #e348ff;
$blue: #2000ed;
$g-color: linear-gradient(25deg, #2000ed, #e348ff);
$card-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
$border: rgb(131 131 131 / 25%);

.uk-text {
  background-color: #2000ed;
  background-image: linear-gradient(25deg, #2000ed, #e348ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
small,
div,
.btn {
  // letter-spacing: -1px;
  font-family: Poppins !important;
  color: white !important;
}

p,
span,
a,
.nav-link,
.nav-item,
.navbar-nav,
input {
  font-family: Poppins !important;
  color: white !important;
}

input,
textarea,
button,
.btn {
  outline: 2px solid transparent !important;
  box-shadow: none !important;
  color: white !important;
}

small {
  font-weight: 500;
  letter-spacing: 0.5px;
}

p {
  font-family: Poppins !important;
  font-size: 15px;
  color: #d6d2da !important;
}

html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $bg-light;
}

html,
body {
  transition: all ease-in-out 0.4s;
  scroll-behavior: smooth;
}

.lg-container {
  // padding: 0px 100px !important;
  max-width: 1400px;
  margin: auto;
}

body {
  font-family: Poppins;
  overflow-x: hidden;
  // background-color: #000000 !important;
  // color: white !important;
  background-color: $main-bg !important;
}

.animatedup {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.animatedleft {
  animation: left-right 1s ease-in-out infinite alternate-reverse both;
}

@keyframes left-right {
  0% {
    transform: translateX(20px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.container-fluid {
  // overflow-x: hidden;
  padding: 0px !important;
}

.btns {
  width: 100%;
  text-align: center;
}

.btn-outline {
  height: 45px;
  width: 145px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 2px solid $main-bg !important;
  color: $main-bg !important;
  font-weight: 500;
  // margin-right: 20px;
  background-color: transparent !important;
}

.btn-fill {
  color: white !important;
  height: 45px;
  width: 145px;
  border: none !important;
  background: $main-bg;
}

.btn-outline:hover {
  box-shadow: $card-shadow !important;
}

.btn-fill:hover {
  box-shadow: $card-shadow !important;
  border: none !important;
}

// .header {
//   min-height: 80px;
//   display: flex;
//   align-items: center;
//   .navbar-brand {
//     img {
//       height: 45px;
//     }
//   }
//   .navbar-toggler {
//     border: none !important;
//   }

//   z-index: 99;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   width: 100%;

//   .navbar {
//     .nav-link {
//       display: flex;
//       align-items: center;
//       padding: 10px 30px !important;
//       color: black;
//       font-weight: 500;
//     }

//     .nav-link:hover {
//       color: $main-bg !important;
//     }

//     .navbar-nav .nav-link:last-child {
//       padding-right: 0px !important;
//     }
//   }
// }
.container-full {
  padding: 0px 70px;
  width: 100%;
}

.header {
  color: #fff;
  .dropdown-menu {
    background: linear-gradient(25deg, #2000ed, #e348ff) !important;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: $blue;
  }
  .navbar {
    background: $main-bg !important;
    min-height: 100px;
    padding: 0 40px;
  }
}

.main_logo {
  width: 200px;
  color: #fff;
}

.navbar-toggler-icon {
  height: auto !important;
  color: #fff !important;
}

.fa-brands {
  font-weight: 400 !important;
}

.main_container {
  // height: 200px;
  padding: 0 40px;
  color: #fff;
  position: relative;

  .top_msg {
    margin-bottom: 80px;
  }

  .main_logo {
    // top: 16px;
    left: -16px;
    position: absolute;
    z-index: 99;
  }

  .hero-subheading {
    display: flex;
    position: relative;
    padding-top: 45px;

    .anim_pic1 {
      position: absolute;
      top: 80%;
      left: -15%;
    }

    .anim_pic2 {
      position: absolute;
      top: -7px;
      right: 270px;
    }

    .coll {
      margin-bottom: 0px !important;
      margin-top: 15px !important;
      margin-right: 30px;
    }

    h5 {
      font-size: 24px !important;
      margin-bottom: 0px !important;
      font-weight: 700;
    }

    p {
      margin: 0px !important;
      color: white !important;
    }

    .uk-text-gradient {
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .uk-h1 {
    font-size: 50px;

    letter-spacing: -0.175rem;
    font-weight: 700;
    padding: 24px 0 0px;
  }

  .uk-text-gradient {
    background-color: #2000ed;
    background-image: linear-gradient(25deg, #2000ed, #e348ff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 2.625rem;
  }

  .main_description {
    .btn {
      margin-top: 15px;
      margin-right: 15px;
      i {
        margin-right: 5px;
      }
    }
    .main_date {
      font-size: 16px !important;
      line-height: 1.4;
      letter-spacing: normal;
      margin-bottom: 20px;
    }

    p {
      color: #d6d2da !important;
      margin-bottom: 0;
      font-size: 14px !important;
      line-height: 1.5;
      letter-spacing: normal;
    }

    a {
      text-decoration: none;

      i {
        margin-right: 5px;
      }
    }
  }

  .main_rightImg {
    position: relative;

    img {
      width: 100%;
    }

    .anim_pic3 {
      position: absolute;
      right: 16%;
    }

    .anim_pic4 {
      position: absolute;
      left: -16%;
      top: 60%;
    }
  }
}
.main-card {
  background-color: #33254b;
  padding: 31px 48px;
  border-radius: 73px;
}

.phase-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
}
.phase-one {
  font-weight: 600;
}
.dollar-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.eth {
  margin-bottom: 5px;
}
.diamonds {
  height: 22px;
  width: 27px;
}
.diamond {
  height: 22px;
  width: 23px;
}
.diamondd {
  height: 27px;
  width: 30px;
}
.diamondss {
  margin-right: 4px;
  height: 18px;
  width: 18px;
}

.big-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 132px;
  width: 50%;
}
.btn-lg {
  width: 100%;
}
.button-type {
  display: flex;
}
.bt-type {
  border: 1px solid grey;
  padding: 13px 43px 0px 31px !important;
  margin-right: 26px !important;
  border-radius: 8px;
}
.feilds {
  margin-top: 15px;
}
.btn-box {
  justify-content: center;
  display: flex;
  align-items: center;
}

.banner-section{
  padding: 140px 60px 80px;
  background-image: url("./images/banner-section-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 0 100px 0;
  .content-wrapper{
    h1{
      font-style: italic;
      font-weight: bold;
      font-size: 40px;
      margin: 0 0 20px;
      span{
        color: #60be99 !important;
      }
    }
    h3{
      font-size: 24px;
      font-style: italic;
      line-height: 38px;
      font-weight: bold;
      span{
        color: #60be99 !important;
      }
    }
  }
  .img-wrapper{
    text-align: right;
    img{
      width: 100%;
    }
  }
}

.section_01 {
  color: #fff;
  padding: 100px 40px;
  text-align: center;
  position: relative;

  h6 {
    font-size: 40px;
    line-height: 1.175;
    margin-bottom: 20px;
    font-weight: 700;
  }

  span {
    color: #d6d2da !important;
    font-family: poppins;
    font-size: 16px;
    font-weight: 400;
  }

  .anim_pic6 {
    bottom: 20%;
    left: 30%;
    position: absolute;
  }

  .anim_pic7 {
    top: 26%;
    right: 26%;
    position: absolute;
  }
}

.section_02 {
  padding: 80px 40px;
  color: #fff;

  .sec02_leftImg {
    padding: 0 30px 30px 0;
  }

  .sec02_rightText {
    padding: 40px 0 40px 40px;
    p {
      text-align: justify;
    }
    span {
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
    }

    h3 {
      font-size: 40px;
      color: #fff;
      font-family: 700;
      margin: 20px 0;
    }
  }
}

.section_03 {
  // padding: 60px 40px;
  color: #fff;

  h2 {
    font-size: 40px;
    line-height: 1.175;
    text-align: center;
    margin-bottom: 40px;
  }

  .card_01 {
    background-color: $bg-light;
    padding: 48px 24px;
    border-radius: 24px;
    position: relative;

    .anim_pic8 {
      top: -18%;
      left: 35%;
      position: absolute;
    }

    .anim_pic9 {
      bottom: 10%;
      left: -32%;
      position: absolute;
    }

    span {
      font-size: 40px;
      font-weight: 700;
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .card_content {
      margin-top: 24px;

      h3 {
        color: #fff;
        font-size: 18px;

        font-weight: 700;
      }

      p {
        margin-top: 20px;
        color: #d6d2da !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    }
  }

  .card_02 {
    background-color: $bg-light;
    padding: 48px 24px;
    border-radius: 24px;
    margin-top: 48px;
    position: relative;

    .anim_pic10 {
      bottom: 10%;
      right: -32%;
      position: absolute;
    }

    span {
      font-size: 40px;
      font-weight: 700;
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .card_content {
      margin-top: 24px;

      h3 {
        color: #fff;
        font-size: 18px;

        font-weight: 700;
      }

      p {
        margin-top: 20px;
        color: #d6d2da !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        margin-bottom: 0;
      }
    }
  }
}

.section_04 {
  padding: 80px 40px;
  color: #fff;

  .sec04_rightImg {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 40px 40px;
  }

  .sec04_leftText {
    padding: 0 50px 30px 0;

    span {
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px !important;
      font-weight: 500 !important;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
    }

    h3 {
      font-size: 34px;
      color: #fff;
      font-family: 700;
      margin: 20px 0;
    }

    .column_section {
      margin-top: 48px;

      .section_01 {
        display: flex;
        padding: 10px 0 48px 0;
        // justify-content: space-between;
        align-items: center;

        .icon {
          padding: 16px;
          background-color: #2000ed;
          background-image: linear-gradient(25deg, #2000ed, #e348ff);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          i {
            color: #fff;
            font-size: 14px;
          }
        }

        .content {
          padding-left: 24px;
          font-size: 13px;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: left;
        }
      }
    }
  }
}

.section_05 {
  // padding: 40px 150px;
  color: #fff;

  .section_01 {
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-radius: 24px;
    padding: 48px 24px;
    display: flex;
    align-items: center;
    margin: 0 0 30px 10px;
    position: relative;

    .anim_pic11 {
      position: absolute;
      top: -35%;
      left: 15%;
    }

    .anim_pic12 {
      position: absolute;
      bottom: 32%;
      left: -18%;
    }

    .anim_pic13 {
      position: absolute;
      bottom: 32%;
      right: -18%;
    }

    .sec01_cardImg {
      width: 25%;
    }

    .sec01_content {
      text-align: left;
      padding-left: 48px;

      h3 {
        font-size: 24px;
        line-height: 1.125;
        font-weight: 700;
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: normal;
        margin: 0;
      }
    }
  }
}

.section_06 {
  padding: 40px 0;

  h2 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.175;
  }
}

.section_07 {
  padding: 80px;

  p {
    text-align: center;
    color: #d6d2da !important;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
  }

  .brands_bx {
    display: flex;
    justify-content: space-between;

    .brand_logo {
      padding: 50px;
    }
  }
}

.section_08 {
  padding: 80px 60px;
  color: #fff;

  h2 {
    font-size: 40px;
    line-height: 1.175;
    text-align: center;
    margin-bottom: 40px;
  }

  .section_container {
    // display: flex;
    // justify-content: space-around;

    .team_mem_01 {
      background-color: $bg-light;
      display: flex;
      flex-direction: column;
      padding: 8px;
      margin: 10px;
      border-radius: 32px;
      align-items: center;
      margin-bottom: 32px;

      img {
        border-radius: 24px;
      }

      .mem_data {
        padding: 24px 10px 10px;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          font-size: 16px;
          line-height: 1.5;
          color: #fff;
          margin-bottom: 5px;
          font-weight: 700;
          letter-spacing: 0;
          text-align: center;
        }
        span {
          margin-bottom: 5px;
        }
        p {
          color: #d6d2da !important;
          font-size: 12px;
          line-height: 1.5;
          text-align: center !important;
          margin: 0;
        }

        ul {
          display: flex;
          list-style-type: none;
          justify-content: space-between;
          padding: 0;
          margin-bottom: 0;
          margin-top: 16px;
        }
      }
    }

    .team_mem_02 {
      background-color: $bg-light;
      display: flex;
      flex-direction: column;
      padding: 8px;
      border-radius: 32px;
      align-items: center;
      margin-top: 32px;

      img {
        border-radius: 24px;
      }

      .mem_data {
        padding: 24px;

        h3 {
          font-size: 16px;
          line-height: 1.5;
          color: #fff;
          margin-bottom: 5px;
          font-weight: 700;
          letter-spacing: 0;
          text-align: center;
        }

        p {
          color: #d6d2da !important;
          font-size: 12px;
          line-height: 1.5;
          text-align: center !important;
          margin: 0;
        }

        ul {
          display: flex;
          list-style-type: none;
          justify-content: space-between;
          padding: 0;
          margin-bottom: 0;
          margin-top: 16px;
        }
      }
    }
  }
}

.section_09 {
  // padding: 80px 60px;

  h2 {
    text-align: center;
    font-size: 40px;
    line-height: 1.175;
    color: #fff;
    font-weight: 700;

    span {
      background-color: #2000ed;
      background-image: linear-gradient(25deg, #2000ed, #e348ff);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 40px;
      line-height: 1.175;
    }
  }

  .section_container {
    padding-top: 80px;
    // color: #fff;

    .accordion {
      --bs-accordion-bg: $bg-light !important;
      --bs-accordion-border-radius: 24px !important;
      --bs-accordion-border-color: rgb(131 131 131 / 25%) !important;
      --bs-accordion-border-width: 1px;
      padding-top: 50px !important;
    }

    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: 24px !important;
      border-top-right-radius: 24px !important;
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: 24px !important;
      border-bottom-left-radius: 24px !important;
    }

    .accordion-body {
      padding: 0 32px 32px 32px !important;
      color: #d6d2da !important;
      font-size: 17px;
      line-height: 1.5;
      letter-spacing: normal;
    }

    .accordion-button {
      padding: 32px !important;
      font-size: 20px !important;
      line-height: 1 !important;
      font-weight: 700 !important;
    }

    .accordion-button:not(.collapsed) {
      background-color: $bg-light !important;
    }

    // .accordion-button::after {
    //   background-image: var(--bs-accordion-btn-icon);
    //   color: white ;
    // }

    .card-body {
      padding: 0 32px !important;

      p {
        color: #d6d2da !important;
        margin: 20px 0px;
      }
    }

    .sec_09_img {
      padding-left: 130px;
    }
  }
}

.section_contact {
  border-top: 1px solid rgb(131 131 131 / 25%) !important;

  padding: 20px;

  h2 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    line-height: 1.175;
    font-weight: 700;
  }

  .section_container {
    margin-top: 48px;
    // color: #fff;
    width: 60%;
    background-color: $bg-light !important;
    margin-left: auto;
    margin-right: auto;
    padding: 48px;
    border-radius: 24px;
    border: 1px solid rgb(131 131 131 / 25%) !important;
    text-align: center;

    .field_01 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      input {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05) !important;
        color: #fff !important;
        height: 48px;
        padding: 0 20px;
        font-size: 16px;
        border: none;
        border-radius: 56px;
        font-weight: 700;
      }

      textarea {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.05) !important;
        color: #fff !important;
        height: 150px;
        padding: 20px;
        font-size: 16px;
        border: none;
        border-radius: 26px;
        font-weight: 700;
      }
    }

    button {
      width: 264px;
      background-color: #741ff5;
      color: #fff;
      cursor: pointer;
      border: none;
      outline: none;
      text-align: center;
      border-radius: 56px;
      margin-top: 15px;
      height: 48px;
      // display: flex;
      justify-content: center;
      font-size: 0.875rem;
      font-weight: 500;
    }

    button:hover {
      background-color: #9351f7;
    }
  }
}

.main-btn {
  background: $g-color !important;
  color: #fff;
  cursor: pointer;
  border: none !important;
  border-radius: 100px !important;
  // outline: none;
  text-align: center;
  height: 44px;
  max-width: 200px !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
}

.main-btn:hover {
  background-color: #9351f7;
}
.footer_section {
  padding: 80px;
  display: flex;
  justify-content: center;

  .section_container {
    width: 40%;
    text-align: center;
    position: relative;

    p {
      margin-top: 32px;
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
    }

    .icons {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;

      i {
        color: #fff;
        margin: 0px 14px;
        font-size: 22px;
      }
    }

    .anim_pic15 {
      position: absolute;
      top: 45%;
      left: -35%;
    }

    .anim_pic14 {
      position: absolute;
      bottom: -25%;
      right: 27%;
    }

    .anim_pic16 {
      position: absolute;
      top: -15%;
      right: -26%;
    }
  }
}

.footer_bottom {
  padding: 20px 80px 80px 80px;
  position: relative;

  .section_container {
    border-top: 1px solid rgb(131 131 131 / 25%) !important;
    justify-content: center;
    display: flex;
    padding-top: 24px;

    span {
      color: #d6d2da;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .anim_pic17 {
    position: absolute;
    bottom: 26%;
    left: 32%;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container,
  .main_container .hero-subheading {
    // flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container .team_mem_01,
  .section_08 .section_container .team_mem_02,
  .section_03 .card_02,
  .section_03 .card_01 {
    margin-bottom: 25px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .uk-h1 {
    letter-spacing: 0px;
    margin: 0;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .main_description .main_date {
    font-size: 14px !important;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container .team_mem_01 .mem_data,
  .section_08 .section_container .team_mem_02 .mem_data {
    padding: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container .team_mem_01 .mem_data ul,
  .section_08 .section_container .team_mem_02 .mem_data ul {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .main_rightImg {
    margin-top: 30px;
    padding: 20px;
    // display: none;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .main_rightImg .anim_pic3 {
    top: -20px;
    right: 12%;
    width: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .anim_pic5 {
    bottom: 0%;
    left: 4%;
  }
}

@media only screen and (max-width: 768px) {
  .section_01 .anim_pic7 {
    top: 26%;
    right: 6%;
  }
}

@media only screen and (max-width: 768px) {
  .section_01 .anim_pic6 {
    bottom: 10%;
    left: 10%;
  }
}

// @media only screen and (max-width: 768px) {
//   .section_01,
//   .section_03,
//   .section_05,
//   .section_09,
//   .section_contact,
//   .footer_section,
//   .footer_bottom,
//   .section_07 .brands_bx .brand_logo {
//     padding: 20px;
//   }
// }

@media only screen and (max-width: 768px) {
  .section_04 .sec04_leftText .column_section .section_01 {
    padding: 10px 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section_02,
  .section_04,
  .section_07 {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 {
    padding: 20px 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer_bottom .anim_pic17 {
    bottom: 0%;
    top: -86px;
    left: 8%;
    width: 16px !important;
  }

  @media only screen and (max-width: 768px) {
    .section_05 .section_01 .anim_pic12 {
      position: absolute;
      bottom: 32%;
      left: -12%;
      width: 20px !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main_container .top_msg {
    margin: 30px 0 0 0;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 h2 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .section_02 .sec02_rightText p {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .hero-subheading p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .uk-h1 {
    font-size: 28px !important;
    padding: 20px 0 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .section_01 h6 {
    font-size: 30px !important;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .section_02 .sec02_rightText h3 {
    font-size: 30px !important;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .main_description {
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 h2 span {
    font-size: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .m-4 {
    margin: 0.1rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_01 h6 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .section_01 span {
    font-size: 14px;
  }
}

// @media only screen and (max-width: 768px) {
//   .section_02 .sec02_leftImg {
//     padding: 0 30px 30px 30px;
//   }
// }

@media only screen and (max-width: 768px) {
  .main_container .main_rightImg .anim_pic4 {
    position: absolute;
    left: -3%;
    top: -25%;
    width: 26px !important;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .hero-subheading .anim_pic2 {
    // position: absolute;
    top: -80px;
    right: 40px;
    bottom: 90px;
  }
}

@media only screen and (max-width: 768px) {
  .main_container .hero-subheading {
    padding-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .footer_section .section_container .anim_pic16 {
    position: absolute;
    top: -9%;
    right: 2%;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 .section_container,
  .header .navbar {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .main_logo {
    width: 150px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_03 .card_02 .anim_pic10 {
    bottom: -16%;
    right: 6%;
    position: absolute;
    width: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer_section .section_container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .section_contact .section_container .field_01 {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .section_contact .section_container .field_01 input {
    margin-left: 0 !important;
    margin-bottom: 15px;
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_contact .section_container {
    margin-top: 10px;
    width: 100%;
    padding: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 .section_container .accordion {
    padding-top: 0px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 .section_container .accordion-button {
    padding: 15px !important;
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 .section_container .card-body {
    padding: 0 15px !important;
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_09 .section_container .sec_09_img {
    padding: 20px 50px;
  }
}

@media only screen and (max-width: 768px) {
  .section_contact .section_container button {
    width: 130px;
    height: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .footer_section .section_container p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 768px) {
  .footer_section .section_container .icons i {
    font-size: 22px;
  }
}

@media only screen and (max-width: 768px) {
  .section_07 .brands_bx .brand_logo {
    width: 50%;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .section_08 .section_container .team_mem_01,
  .section_08 .section_container .team_mem_02 {
    width: 212px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_07 .brands_bx {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 768px) {
  .section_04 .sec04_leftText .column_section {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 {
    margin: 0;
    padding: 15px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 .sec01_content {
    text-align: left;
    padding-left: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 .sec01_cardImg {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 .sec01_content h3 {
    font-size: 18px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 .sec01_content p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .section_05 .section_01 .anim_pic13 {
    bottom: -32%;
    right: 18%;
    width: 16px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_04 .sec04_rightImg {
    padding: 10px 40px;
  }
}

@media only screen and (max-width: 768px) {
  .section_02 .sec02_rightText {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section_03 .card_01,
  .section_03 .card_02 {
    padding: 18px 12px;
  }
}

@media only screen and (max-width: 768px) {
  .main_container {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section_03 .card_01 .card_content,
  .section_03 .card_02 .card_content,
  .section_03 .card_02 .card_content p,
  .section_03 .card_01 .card_content p {
    margin: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .section_03 .card_01 .card_content h3,
  .section_03 .card_02 .card_content h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .section_03 .card_01 .card_content p,
  .section_03 .card_02 .card_content p {
    font-size: 13px !important;
  }
}

@media only screen and (max-width: 768px) {
  .section_04 .sec04_leftText h3 {
    font-size: 30px !important;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .section_04 .sec04_leftText {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .section_04 .sec04_leftText p {
    font-size: 13px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dashboard-body {
  padding: 30px;
  min-height: 100vh;
  color: white;
  .dashboard-head {
    .buttons {
      display: flex;
      .btn {
        margin-left: 15px;
      }
    }
    min-height: 80px;
    width: 100%;
    align-items: center;
    background-color: $bg-light;
    border-radius: 20px;
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    border: 1px solid $border;
    .logo img {
      height: 100%;
    }
  }
}
.dashboard-main {
  padding-top: 20px;
}
.counter {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // width: 100%;
  margin-bottom: 20px;
  .item {
    // width: 100%;
    padding-right: 30px;
    // text-align: center !important;
    // border-right: 1px solid rgba(255, 255, 255, 0.366);
    background-color: #2000ed;
    background-image: linear-gradient(25deg, #2000ed, #e348ff);
    // background-clip: text;
    font-weight: bolder;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      font-size: 42px;
      // line-height: 32px;
      font-weight: 800 !important;
      font-family: Poppins !important;
      margin: 0px;
      color: white;
    }
    p {
      line-height: normal;
      font-weight: 400;
      font-size: 18px;
      font-weight: 800 !important;
      font-family: Poppins !important;
      margin: 0px;
      color: white;
    }
  }
  .item:last-child {
    border-right: none;
  }
}
.side-bar {
  height: 100%;
  min-height: calc(100vh - 170px);
  width: 100%;
  background-color: $bg-light;
  border-radius: 20px;
  padding: 20px 20px;
  border: 1px solid $border;
  .nav-link-btns {
    .nav-btn {
      font-weight: 500;
      letter-spacing: 0.5px;
      font-size: 16px;
      margin-bottom: 5px;
      min-height: 50px;
      // text-transform: uppercase;
      width: 100%;
      transition: all ease-in-out 0.4s;
      padding: 13px 20px 7px;
      display: flex;
      text-align: left;
      align-items: baseline;
      outline: none !important;
      border: none;
      // background-color: red;
      border-radius: 10px;
      i {
        margin-right: 10px !important;
      }
    }
    .nav-btn:hover,
    .nav-btn[selectedmethod="true"] {
      transition: all ease-in-out 0.4s;
      background: $g-color !important;
    }
    .nav-btn[selectedmethod="true"] {
      transition: all ease-in-out 0.4s;
      background: $g-color !important;
    }
  }
}
.section_06 {
  padding: 40px 0;

  h2 {
    text-align: center;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.175;
  }

  .section_container {
    padding: 40px 150px;
    @media screen and (max-width: 1400px) {
      padding: 40px 60px;
    }
    @media screen and (max-width: 1200px) {
      padding: 10px 0px;
    }
    .timeline {
      position: relative;
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      padding: 1em 0;
      list-style-type: none;
    }

    .timeline:before {
      position: absolute;
      left: 50%;
      top: 0;
      content: " ";
      display: block;
      width: 1px;
      height: 100%;
      margin-left: -3px;
      background: rgb(80, 80, 80);
      background: -moz-linear-gradient(
        top,
        rgba(80, 80, 80, 0) 0%,
        rgb(80, 80, 80) 8%,
        rgb(80, 80, 80) 92%,
        rgba(80, 80, 80, 0) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, rgba(30, 87, 153, 1)),
        color-stop(100%, rgba(125, 185, 232, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(80, 80, 80, 0) 0%,
        rgb(80, 80, 80) 8%,
        rgb(80, 80, 80) 92%,
        rgba(80, 80, 80, 0) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(80, 80, 80, 0) 0%,
        rgb(80, 80, 80) 8%,
        rgb(80, 80, 80) 92%,
        rgba(80, 80, 80, 0) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(80, 80, 80, 0) 0%,
        rgb(80, 80, 80) 8%,
        rgb(80, 80, 80) 92%,
        rgba(80, 80, 80, 0) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(80, 80, 80, 0) 0%,
        rgb(80, 80, 80) 8%,
        rgb(80, 80, 80) 92%,
        rgba(80, 80, 80, 0) 100%
      );

      z-index: 0;
    }

    .timeline li {
      padding: 1em 0;
    }

    .timeline li:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    .direction-l {
      position: relative;
      width: 470px;
      border: 1px solid black;
      background-color: #1b1229;
      padding: 32px;
      border-radius: 32px;
      float: right;
      text-align: right;
    }

    .direction-r {
      position: relative;
      width: 470px;
      float: left;
      border: 1px solid black;
      background-color: #1b1229;
      padding: 32px;
      border-radius: 32px;
    }

    .flag-wrapper {
      position: relative;
      display: inline-block;
      text-align: center;
    }

    .flag {
      position: relative;
      display: inline;
      // background: rgb(248, 248, 248);
      // padding: 6px 10px;
      border-radius: 5px;

      font-weight: 600;
      text-align: left;

      .flag_title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 1px;
          font-weight: 500;
          background-color: #2000ed;
          padding: 0px 10px;
          border-radius: 3px;
          background-image: linear-gradient(25deg, #2000ed, #e348ff);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
        }

        .flag_num {
          width: 48px;
          height: 48px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 1px;
          border-radius: 100%;
          transform: rotate(16deg);
          line-height: 0.7;
          background-color: #2000ed;
          background-image: linear-gradient(25deg, #2000ed, #e348ff);

          span {
            font-weight: 700;
            // font-size: 150%;
            font-size: 20px;
          }
        }
      }

      h3 {
        // margin-top: 20px;
        font-size: 28px;
        line-height: 1.15;
        margin-bottom: 0;
        font-size: 700;
        color: #fff;
      }

      p {
        color: #d6d2da !important;
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }
    }

    .direction-l .flag {
      -webkit-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
      box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
    }

    .direction-r .flag {
      -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.15);
    }

    .direction-r .flag:before {
      position: absolute;
      top: 50%;
      // right: -40px;
      content: " ";
      display: block;
      width: 25px;
      height: 25px;
      margin-top: -10px;
      background: linear-gradient(25deg, #2000ed, #e348ff);
      border-radius: 20px;
      border: 6px solid #0f051d;
      box-shadow: 0 0 0 1.5px rgb(80 80 80);

      // padding: 20px;
      z-index: 10;
    }

    .direction-l .flag:before {
      position: absolute;
      top: 50%;
      left: -278.5px;
      content: " ";
      display: block;
      width: 25px;
      height: 25px;
      margin-top: -10px;
      background: linear-gradient(25deg, #2000ed, #e348ff);
      border-radius: 20px;
      border: 6px solid #0f051d;
      box-shadow: 0 0 0 1.5px rgb(80 80 80);

      // padding: 20px;
      z-index: 10;
    }

    .direction-r .flag:before {
      right: -70.5px;
    }

    .direction-l .flag:after {
      content: "";
      position: absolute;
      left: 100%;
      top: 50%;
      height: 0;
      width: 0;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(248, 248, 248);
      border-width: 8px;
      pointer-events: none;
      display: none;
    }

    .direction-r .flag:after {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      height: 0;
      width: 0;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(248, 248, 248);
      border-width: 8px;
      pointer-events: none;
      display: none;
    }

    .time-wrapper {
      display: inline;

      line-height: 1em;
      font-size: 0.66666em;
      color: rgb(250, 80, 80);
      vertical-align: middle;
    }

    .direction-l .time-wrapper {
      float: left;
    }

    .direction-r .time-wrapper {
      float: right;
    }

    .time {
      display: none;
      padding: 4px 6px;
      background: rgb(248, 248, 248);
    }

    .desc {
      margin: 1em 0.75em 0 0;

      font-size: 0.77777em;
      font-style: italic;
      line-height: 1.5em;
    }

    .direction-r .desc {
      margin: 1em 0 0 0.75em;
    }

    /* ================ Timeline Media Queries ================ */

    @media screen and (max-width: 660px) {
      .timeline {
        width: 100%;
        padding: 4em 0 1em 0;
      }

      .timeline li {
        padding: 2em 0;
      }

      .direction-l,
      .direction-r {
        float: none;
        width: 100%;

        text-align: center;
      }

      .flag-wrapper {
        text-align: center;
      }

      .flag {
        background: rgb(255, 255, 255);
        z-index: 15;
      }

      .direction-l .flag:before,
      .direction-r .flag:before {
        position: absolute;
        top: -30px;
        left: 0%;
        content: " ";
        display: block;
        width: 12px;
        height: 12px;
        margin-left: -9px;
        background: #fff;
        border-radius: 10px;
        border: 4px solid rgb(255, 80, 80);
        z-index: 10;
      }

      .direction-l .flag:after,
      .direction-r .flag:after {
        content: "";
        position: absolute;
        left: 50%;
        top: -8px;
        height: 0;
        width: 0;
        margin-left: -8px;
        border: solid transparent;
        border-bottom-color: rgb(255, 255, 255);
        border-width: 8px;
        pointer-events: none;
      }

      .time-wrapper {
        display: block;
        position: relative;
        margin: 4px 0 0 0;
        z-index: 14;
      }

      .direction-l .time-wrapper {
        float: none;
      }

      .direction-r .time-wrapper {
        float: none;
      }

      .desc {
        position: relative;
        margin: 1em 0 0 0;
        padding: 1em;
        background: rgb(245, 245, 245);
        -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);

        z-index: 15;
      }

      .direction-l .desc,
      .direction-r .desc {
        position: relative;
        margin: 1em 1em 0 1em;
        padding: 1em;

        z-index: 15;
      }
    }

    // @media screen and (min-width: 400px ?? max-width: 660px) {

    //   .direction-l .desc,
    //   .direction-r .desc {
    //     margin: 1em 4em 0 4em;
    //   }

    // }
  }
}
.content-body {
  height: 100%;
  // min-height: calc(100vh - 170px);
  width: 100%;
}
.dashboard-title {
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid $border;
}
.dashboard-main {
  h2 {
    font-size: 28px !important;
  }
  p {
    font-size: 14px !important;
  }
}
.dashboard-card {
  min-height: 80px;
  width: 100%;
  background-color: $bg-light;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  margin-bottom: 25px;
  border: 1px solid $border;
  .icon-box {
    height: 65px;
    width: 65px;
    background: $g-color;
    border-radius: 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 28px;
    }
  }
  .content {
    width: calc(100% - 80px);

    h3 {
      margin-bottom: 0px;
      font-size: 28px;
    }
    small {
      margin-bottom: 0px;
      font-size: 16px;
      color: #f5f5f5 !important;
      font-weight: 500 !important;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

.swiping {
  max-width: 500px;
  margin: auto;
  margin-top: 60px;
  .swip-card {
    min-height: calc(100% - 25px);
    margin: auto;
    background-color: $bg-light;
    border-radius: 10px;
    padding: 20px;
    // display: flex;
    margin-bottom: 25px;
    border: 1px solid $border;
    .w-100 {
      width: 100%;
    }
    .right {
      width: 100px !important;
    }
    .left {
      width: calc(100% - 100px) !important;
    }
    .heading2 {
      display: flex;
      margin-bottom: 15px !important;
      justify-content: space-between;
      .name {
        font-size: 18px;
        font-weight: 700;
      }
      .btn-dark {
        border: none !important;
        background-color: transparent !important;
      }
      i {
        font-size: 20px;
      }
      .dropdown-toggle::after {
        display: none;
      }
    }
    .swap-btn {
      min-height: 55px;
      width: 100%;
      border-radius: 10px;
      color: white;
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
    }
    .swiper-arrow {
      width: 100%;
      position: relative;
      height: 1px;
      // background-color: red;
      .arrow {
        position: absolute;
        top: -25px;
        left: 45%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #f5f5f527;
        background-color: $main-bg;
        width: 50px;
        border-radius: 100px;
        i {
          font-size: 22px;
        }
      }
    }
    .swip-box {
      min-height: 100px;
      width: 100%;
      border: 2px solid #f5f5f527;
      border-radius: 15px;
      margin: 5px 0px;
      padding: 15px 15px 10px;
      background-color: $main-bg;
      .dropdown-menu {
        padding: 0px !important;
      }
      .dropdown-menu-dark .dropdown-item {
        display: flex;
        align-items: center;
      }
      .btn {
        display: flex;
        background: $bg-light;
        padding: 10px 15px 10px 10px !important;
        border-radius: 100px;
        align-items: center;
        img {
          margin-right: 10px !important;
        }
      }

      img {
        height: 26px !important;
      }
      .field-row {
        padding-top: 15px;
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          // -webkit-appearance: none;
          margin: 0;
        }
        h6 {
          font-weight: 400 !important;
          margin-bottom: 0px;
          font-size: 13px;
          color: gray;
        }
        input {
          background-color: transparent;
          border: none;
          font-size: 26px;
          font-weight: 600;
          padding: 0px 10px !important;
          color: #f5f5f5;
        }
        input::placeholder {
          font-size: 26px;
          font-weight: 600;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .swiping .swip-card {
    width: 100% !important;
    padding: 15px !important;
    border-radius: 10px !important;
  }
  .swiping .swip-card .swip-box {
    padding: 10px !important;
  }
  .swiping {
    padding: 40px 0px !important;
  }
}

.voting-card {
  min-height: calc(100% - 25px);

  width: 100%;
  background-color: $bg-light;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid $border;
  h5 {
    letter-spacing: 0.5px;
    font-weight: 600;
  }
  small {
    color: #f5f5f5;
    font-weight: 400 !important;
  }
  .poll-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .poll-btns {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .btn {
      height: 34px;
      padding: 0px 30px !important;
    }
  }
  .progress {
    border: none;
    outline: none;
    background-color: $main-bg;
    border-radius: 100px;
  }
  .progress-bar {
    border: none;
    outline: none;
    border-radius: 100px;
    background: $g-color !important;
  }
}
.progress-bar {
  border-radius: 100px;
}
.create-poll-card {
  min-height: 214px;

  width: 100%;
  background-color: $bg-light;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid $border;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  .fa-solid {
    font-size: 40px;
    color: #d6d2da !important;
    margin-bottom: 20px;
  }
  h4 {
    color: #d6d2da !important;
    margin-bottom: 0px;
  }
}

.create-new-poll {
  min-height: calc(100% - 25px);
  width: 100%;
  background-color: $bg-light;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid $border;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  .btn {
    min-width: 240px;
  }
  small {
    color: #d6d2da !important;
  }
  .form-group {
    margin-bottom: 20px;

    label {
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
    }
    input {
      background-color: $main-bg;
      border: 1px solid $border;
      height: 48px;
    }
  }
}
.render {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease;
}

.show {
  visibility: visible;
  opacity: 1;
}

.dKfWpB,
.bElhDP .hKhOIm {
  background-color: $bg-light !important;
}
.bElhDP .hKhOIm:hover {
  background-color: $main-bg !important;
}
.dashboard-head .dropdown-menu {
  background-color: $bg-light;
  border: 2px solid $border;
  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    background-color: $main-bg;
    border: none !important;
  }
}
.welcome-msg {
  height: 100%;
  min-height: calc(100vh - 170px);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $bg-light;
  border-radius: 20px;
  padding: 40px 20px;
  border: 1px solid $border;

  .content-here {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 100%;
    }
  }
}
.modal-content {
  border-radius: 20px !important;
  background-color: $main-bg !important;
  .modal-body {
    border: 2px solid $border;
    border-radius: 20px;
    min-height: 400px;
    font-family: Poppins !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .icon {
      height: 100px !important;
      width: 100px !important ;
      margin-bottom: 25px;
    }
    h2 {
      margin-bottom: 20px;
    }
    p {
      color: #d6d2da;
      font-size: 14px;
      max-width: 650px;
      font-weight: 300;
      font-family: Poppins !important;
      letter-spacing: 0.5px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 768px) {
  .counter .item span {
    font-size: 32px !important;
  }
  .counter .item p {
    font-size: 14px;
  }
  .counter {
    justify-content: center;
  }
  .counter .item {
    padding: 0px 10px;
    text-align: center;
  }
  .main_container .hero-subheading {
    justify-content: center;
    .coll {
      margin: 0px !important;
      padding: 20px 10px !important;
    }
  }
  .container {
    padding: 0px !important;
  }
}
.mbl-btns {
  span {
    padding: 5px 10px;
    i {
      font-size: 20px;
    }
  }
}

.nav-address {
  white-space: nowrap;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
}
